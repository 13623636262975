import { useLocation, useParams, useSearchParams } from "react-router-dom";
import "./streamView.css";
import { ZIM, ZIMEventOfReceiveConversationMessageResult, ZIMEventOfRoomStateChangedResult, ZIMMessage, ZIMRoomAdvancedConfig, ZIMRoomState } from "zego-zim-web";
import { useEffect, useRef, useState } from "react";
import { Bet, GIFT_TYPE, Stream } from "../model/types";
import { toast } from "react-toastify";
import { GUEST_DISPLAY_NAME_KEY, HttpClient } from "../network";
import { BetResponse, ChatMessage, CreateBetOrStreamResponse, MessageBody, MessageBodyType, PlaceBetResponse, Response, RoomResponse, SelectedChannel, UpdateRoomCommentatorIdResponse, UpdateRoomLivenessResponse, UpdateStreamResponse } from "../network/types";
import { isUserLoggedIn } from "../utils/login";
import { formatTimestamp, getStreamLink } from "../utils/stream";
import { ZegoExpressEngine } from 'zego-express-engine-webrtc'
import ZegoLocalStream from "zego-express-engine-webrtc/sdk/code/zh/ZegoLocalStream.web"
import { isNumber, trackLog } from "../utils/utils";
import { UnmuteButton } from './unmuteButton';

import { BulletCanvas, bulletMessage, maxFloor } from "./bullet";

import { Tooltip } from "react-tooltip";
import { messaging } from "../firebase";
export const getStreamType = (id: string | undefined): SelectedChannel => {
  if (id && id!.startsWith("kick~")) {
    return SelectedChannel.KICK
  } else if (id && id!.startsWith("youtube~")) {
    return SelectedChannel.YOUTUBE
  } else if (id && id!.startsWith("twitch~")) {
    return SelectedChannel.TWITCH
  } else if (id && id!.startsWith("streamed~")) {
    return SelectedChannel.STREAMED
  } else if (id && id!.startsWith("twitter~")) {
    return SelectedChannel.TWITTER
  } else if (id && id!.startsWith("featured~")) {
    return SelectedChannel.FEATURED
  }

  return SelectedChannel.UNKNOWN
}

export const getStreamId = (stream: Stream | undefined) => {
  return (stream?.newStreamId && stream?.newStreamId !== '') ? stream?.newStreamId : stream?.id
}

export const getStreamFrame = (id: string | undefined, isPreview: boolean, screenWidth: number, startTimestamp?: number) => {
  const streamType = getStreamType(id)
  if (streamType === SelectedChannel.KICK) {
    return <iframe className={isPreview ? "previewStreamPlayer" : "streamPlayer"} src={`https://player.kick.com/${id!.split("~")[1]}?autoplay=${isPreview ? 'false' : 'true'}`} allowFullScreen={false} />
  } else if (streamType === SelectedChannel.YOUTUBE) {
    return <iframe className={isPreview ? "previewStreamPlayer" : "streamPlayer"} src={`https://www.youtube.com/embed/${id!.split("~")[1]}?autoplay=${isPreview ? '0' : '1'}`} allow="autoplay" allowFullScreen={false} />
  } else if (streamType === SelectedChannel.TWITCH) {
    return <iframe className={isPreview ? "previewStreamPlayer" : "streamPlayer"} src={`https://player.twitch.tv/?channel=${id!.split("~")[1]}&parent=thelive.bet`} allowFullScreen={false}> </iframe>
  } else if (streamType === SelectedChannel.STREAMED) {
    console.error(`https://embedme.top/embed/${id!.split("~")[1]}`)
    return <iframe className={isPreview ? "previewStreamPlayer" : "streamPlayer"}
      allow="encrypted-media; picture-in-picture;"
      allowFullScreen={false}
      sandbox="allow-same-origin allow-scripts"
      src={`https://embedme.top/embed/${id!.split("~")[1]}`}></iframe>
  } else if (streamType === SelectedChannel.TWITTER) {
    return <div className={isPreview ? "previewStreamPlayer" : "streamPlayer"} style={{
      marginBottom: isPreview ? "0.3rem" : (screenWidth <= 600 ? "0px" : "0px"),
      marginTop: isPreview ? (screenWidth <= 600 ? "0rem" : "-0.5rem") : "0px"
    }}>
      <blockquote data-align="center" data-media-max-width={isPreview ? (screenWidth <= 600 ? 0.9 * screenWidth : 0.3 * screenWidth) : screenWidth} className="twitter-tweet" data-lang="en" data-dnt="true" data-theme="light">
        <a href={`https://twitter.com/${id!.split("~")[1]}/status/${id!.split("~")[2]}`}></a>
      </blockquote>
    </div>
  }

  return <div className="streamPlayer">Can't find this stream</div>
}

function formatDateTime(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}${month}${day}T${hours}${minutes}00`;
}

export const getMessageIcon = (message: ChatMessage) => {
  switch (message.type) {
    case MessageBodyType.RESOLVED_BET:
      return <div className="chatMessagePfpImage">✅</div>
    case MessageBodyType.ADD_BET:
      return <div className="chatMessagePfpImage">➕</div>
    case MessageBodyType.PLACED_BET:
      return <div className="chatMessagePfpImage">💰</div>
    case MessageBodyType.TRENDING_BET:
      return <div className="chatMessagePfpImage">🔥</div>
    case MessageBodyType.UPDATE_STREAM_LINK:
      return <div className="chatMessagePfpImage">🔗</div>
    case MessageBodyType.STOP_BET:
      return <div className="chatMessagePfpImage">🕒</div>
    case MessageBodyType.UPDATE_COMMENTATOR:
      return <div className="chatMessagePfpImage">🎙️</div>
    default:
      return <img className="chatMessagePfpImage" src={message.userPfpUrl} />
  }
}

export const getAddToCalendarButton = (isPreview: boolean, title: string, timestamp: number, link: string) => {
  const startDateTime = new Date(timestamp)
  const endDateTime = new Date(startDateTime.getTime() + 60 * 60 * 1000) // Add 1 hour

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const userAgent = navigator.userAgent || navigator.vendor;
  const timeRange: string = `${formatDateTime(startDateTime)}/${formatDateTime(endDateTime)}`;

  const isOnMobile = /android|iphone|ipad|ipod|windows phone/i.test(userAgent)
  const params = {
    action: 'TEMPLATE',
    details: link,
    text: title,
    ctx: userTimeZone,
    dates: timeRange
  }
  const urlParams = new URLSearchParams(params).toString();

  const url = 'https://calendar.google.com/calendar/render?' + urlParams
  return <div className={isPreview ? "previewSubscribeButton" : "btn streamSubscribeButton"} onClick={(event) => {
    window.open(url, isOnMobile ? '_self' : '_blank')
    return event.stopPropagation()
  }}>Subscribe</div>
}

enum PublishingState {
  REQUESTING_PUBLISH,
  PUBLISHING,
  NOT_PUBLISHING
}

const HAS_SEEN_COMMENTATOR_TUTORIAL_POPUP_KEY = 'HAS_SEEN_COMMENTATOR_TUTORIAL_POPUP_KEY'
const HAS_SEEN_GIFTING_ROCKET_TUTORIAL_POPUP_KEY = 'HAS_SEEN_GIFTING_ROCKET_TUTORIAL_POPUP_KEY'
const HAS_SEEN_SUPERCHAT_TUTORIAL_POPUP_KEY = 'HAS_SEEN_SUPERCHAT_TUTORIAL_POPUP_KEY'

function StreamView(props: any) {
  const location = useLocation()
  let { id } = useParams();
  let zim: ZIM = props.zim
  let zegoExpressEngine: ZegoExpressEngine = props.zegoExpressEngine
  let zimToken = props.zimToken
  let isIMLoggedIn = props.isIMLoggedIn
  let init = props.init
  let login = props.login
  let userAddress = props.userAddress
  let guestId = props.guestId
  let userCoins = props.userCoins
  let setUserCoins = props.setUserCoins
  let setUserPoints = props.setUserPoints
  let updateUserCoinBalance = props.updateUserCoinBalance
  let isResolver = props.isResolver
  let isCommunityMod = props.isCommunityMod
  let displayName = props.displayName
  let profilePicUrl = props.profilePicUrl
  let screenWidth = props.screenWidth
  let betCreationTakeRate = props.betCreationTakeRate
  let betCreationCost = props.betCreationCost
  let overallTakeRate = props.overallTakeRate
  let commentatorTakeRate = props.commentatorTakeRate
  let giftPrice = props.giftPrice
  let mixpanel = props.mixpanel
  const localStreamRef = useRef<ZegoLocalStream | null>(null)
  const mediaStreamRef = useRef<MediaStream | null>(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreatingBet, setIsCreatingBet] = useState<boolean>(false);
  const [isCreatingBetOnBackend, setIsCreatingBetOnBackend] = useState<boolean>(false);
  const [isPlacingBetOnBackend, setIsPlacingBetOnBackend] = useState<boolean>(false);
  const [inCreateBetDisplayName, setInCreateBetDisplayName] = useState<string>("");
  const [inCreateBetOptions, setInCreateBetOptions] = useState<string[]>(["", ""]);
  const [roomId, setRoomId] = useState<string>("");
  const [streamId, setStreamId] = useState<string>("");
  const [streamCommentatorId, setStreamCommentatorId] = useState<string | undefined>("");
  const [streamCommentatorDisplayName, setStreamCommentatorDisplayName] = useState<string | undefined>("");
  const [streamCommentatorProfilePicUrl, setStreamCommentatorProfilePicUrl] = useState<string | undefined>("");
  const [streamCreatorId, setStreamCreatorId] = useState<string | undefined>("");
  const [streamTitle, setStreamTitle] = useState<string | undefined>("");
  const roomIdRef = useRef<string>()
  const localStreamPlayerRef = useRef<HTMLDivElement>(null);
  const remoteStreamPlayerRef = useRef<HTMLDivElement>(null);
  roomIdRef.current = roomId
  const [bets, setBets] = useState<Bet[]>([]);
  const [trendingBet, setTrendingBet] = useState<Bet>();
  const [isStreamOver, setIsStreamOver] = useState<boolean>(true);
  const [streamStartTime, setStreamStartTime] = useState<number | undefined>();
  const [isShowingTrendingBet, setIsShowingTrendingBet] = useState<boolean>(true);
  const [selectedBetId, setSelectedBetId] = useState<string>("");
  const [selectedBetOptionIndex, setSelectedBetOptionIndex] = useState<number>(-1);
  const [selectedBetIdForSharing, setSelectedBetIdForSharing] = useState<string>("");
  const [selectedBetIdForMod, setSelectedBetIdForMod] = useState<string>("");
  const [selectedResolveOptionIndex, setSelectedResolveOptionIndex] = useState<number>(-1);
  const [isShowingSharePopup, setIsShowingSharePopup] = useState<boolean>(false);
  const [placedBetAmount, setPlacedBetAmount] = useState<number>(10);
  const [afterBetWinningCoinsAmount, setAfterBetWinningCoinsAmount] = useState<number>(0);
  const [countdownRemainingTime, setCountdownRemainingTime] = useState<number>(0);
  const [isMarkingTrendingBet, setIsMarkingTrendingBet] = useState<boolean>(false);
  const [isShowingStopBetPopup, setIsShowingStopBetPopup] = useState<boolean>(false);
  const [isShowingMarkStreamLiveStatusPopup, setIsShowingMarkStreamLiveStatusPopup] = useState<boolean>(false);
  const [isShowingSwapStreamLinkPopup, setIsShowingSwapStreamLinkPopup] = useState<boolean>(false);
  const [isShowingUpdateCommentatorPopup, setIsShowingUpdateCommentatorPopup] = useState<boolean>(false);
  const [isShowingReportStreamPopup, setIsShowingReportStreamPopup] = useState<boolean>(false);
  const [isShowingCommentatorTutorialPopup, setIsShowingCommentatorTutorialPopup] = useState<boolean>(false);
  const [isShowingGiftingRocketTutorialPopup, setIsShowingGiftingRocketTutorialPopup] = useState<boolean>(false);
  const [isShowingSuperChatTutorialPopup, setIsShowingSuperChatTutorialPopup] = useState<boolean>(false);
  const [inCreateSwapStreamUrl, setInCreateSwapStreamUrl] = useState<string>("");
  const [inCreateReportStreamReason, setInCreateReportStreamReason] = useState<string>("");
  const [inUpdateCommentatorAddress, setInUpdateCommentatorAddress] = useState<string>("");
  const [isResolvingBet, setIsResolvingBet] = useState<boolean>(false);
  const [publishingState, setPublishingState] = useState<PublishingState>(PublishingState.NOT_PUBLISHING);
  const [isPlayingStream, setIsPlayingStream] = useState<boolean>(false);
  const [hasCommentatorVideoOn, setHasCommentatorVideoOn] = useState<boolean>(true);
  const [isTogglingCommentatorVideo, setIsTogglingCommentatorVideo] = useState<boolean>(false);
  const [chatBoxContent, setChatBoxContent] = useState("");
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [roomLiveUserCount, setRoomLiveUserCount] = useState<number>(0);
  const streamerRoomChatListRef = useRef<HTMLDivElement>(null);
  const popupContainerRef = useRef<HTMLDivElement>(null);
  const [bulletMessages, setBulletMessages] = useState<bulletMessage[]>([])
  const handleNewBulletMessage = (newMessage: ChatMessage) => {
    let newBulletMessage: bulletMessage = {
      ...newMessage,
      createdAt: Math.floor(Date.now() / 1000),
      floor: 0,
      withPadding: (publishingState === PublishingState.PUBLISHING || isPlayingStream)
    }

    setBulletMessages((preMessage) => {
      let lastMessage = preMessage[preMessage.length - 1]
      if (lastMessage != undefined) {
        if ((newBulletMessage.createdAt - lastMessage.createdAt) < 3) {
          newBulletMessage.floor = lastMessage.floor + 1
          if (newBulletMessage.floor >= maxFloor) {
            newBulletMessage.floor = 0
          }
        }
      }

      if (newBulletMessage.floor > 0) {
        let firstMessage = preMessage[preMessage.length - newBulletMessage.floor]
        if (firstMessage != undefined) {
          if ((newBulletMessage.createdAt - firstMessage.createdAt) >= 3) {
            // got a seat
            newBulletMessage.floor = 0
          }
        }
      }

      return [...preMessage, newBulletMessage]
    })

    setTimeout(() => {
      // remove this message from bullet msg list after a while
      setBulletMessages((prevMessages) => prevMessages.slice(1));
    }, 30_000);
  }

  useEffect(() => {
    init()
    zim.on('receiveRoomMessage', (zim: any, message: ZIMEventOfReceiveConversationMessageResult) => {
      handleMessage(message.messageList)
    });
    zim.on('roomStateChanged', (zim: ZIM, roomStateChangedResult: ZIMEventOfRoomStateChangedResult) => {
      handleRoomStateChange(roomStateChangedResult)
    })
    zim.on('error', (e) => { console.log("!!!!!", { e }) })
    zegoExpressEngine.on('publisherStateUpdate', (result) => {
      trackLog('result.state', result?.state)
      console.error(result.state)
      if (result.state === 'PUBLISHING') {
        setPublishingState(PublishingState.PUBLISHING)
      } else if (result.state === 'NO_PUBLISH') {
        setPublishingState(PublishingState.NOT_PUBLISHING)
      }
    });
    zegoExpressEngine.on('roomStreamUpdate', async (streamRoomID, updateType, streamList, extendedData) => {
      if (streamRoomID.substring(0, 32) !== id!.substring(0, 32)) {
        trackLog("roomStreamUpdate room id doesn't match", { streamRoomID, roomId })
        return
      }
      trackLog('roomStreamUpdate(streamRoomID, updateType)', streamRoomID, updateType)
      if (updateType == 'ADD') {
        startPlayingStream()
      } else if (updateType == 'DELETE') {
        stopPlayingStream()
      }
    })
    zegoExpressEngine.on('remoteCameraStatusUpdate', async (streamRoomID, on) => {
      if (streamRoomID.substring(0, 32) !== id!.substring(0, 32)) {
        trackLog("remoteCameraStatusUpdate room id doesn't match", { streamRoomID, id })
        return
      }
      trackLog('remoteCameraStatusUpdate(streamRoomID, on)', streamRoomID, on)
      setHasCommentatorVideoOn(on === "OPEN")
    })

    fetchRoomInfo()
  }, [id, publishingState])

  useEffect(() => {
    const handleLeavePage = () => {
      trackLog("Leaving the StreamView", location.pathname);
      zegoExpressEngine.off('roomStreamUpdate')
      stopPublishingStream()
      stopPlayingStream()
    };

    return () => {
      handleLeavePage();
    };
  }, [id, publishingState]);

  useEffect(() => {
    if (isIMLoggedIn && roomId) {
      enterRoom()
    }
  }, [isIMLoggedIn, roomId]);

  useEffect(() => {
    let userID: string
    if (userAddress && userAddress !== "") {
      userID = userAddress.substring(0, 32)
    } else {
      userID = guestId.substring(0, 32)
    }
    if (zimToken && roomId && (userAddress || guestId)) {
      zegoExpressEngine.logoutRoom()
      zegoExpressEngine.loginRoom(id!, zimToken, { userID: userID }, { userUpdate: true })
        .then((response) => {
          console.log("zegoExpressEngine logined room", { roomId, userAddress, userID })
        })
        .catch((err) => {
          console.error("zegoExpressEngine loginRoom", err)
          toast.error(err)
        })
    }
  }, [zimToken, roomId, userAddress, guestId]);

  useEffect(() => {
    const updateTimers = () => {
      const currentTime = Date.now();

      if (getStreamType(streamId) === SelectedChannel.FEATURED) {
        setCountdownRemainingTime(Math.max(streamStartTime! - currentTime, 0))
      }
    };

    // Initialize and update every second
    const interval = setInterval(() => {
      updateTimers();
    }, 1000);

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, [streamId, streamStartTime]);

  useEffect(() => {
    if (bets.length > 0) {
      bets.sort((betA, betB) => {
        if (betA.resolvedOption != betB.resolvedOption) {
          return (betA.resolvedOption < 0) ? -1 : 1
        }

        if (betA.createdAt != betB.createdAt) {
          return (betA.createdAt < betB.createdAt) ? 1 : -1
        }

        return betA.id.localeCompare(betB.id)
      })
      const queryBetId = searchParams.get('bid')

      if (queryBetId) {
        if (!trendingBet || trendingBet.id !== queryBetId) {
          const queryBet = bets.find((bet) => bet.id === queryBetId)
          if (queryBet) {
            setIsShowingTrendingBet(false)

            setTimeout(() => {
              const element = document.getElementById("betid~" + queryBetId)

              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }, 50)
          }
        }

        setSearchParams("")
      }
    }
  }, [bets]);

  const enterRoom = () => {
    if (isIMLoggedIn) {
      zim.enterRoom({ roomID: id!.substring(0, 32)!, roomName: id!.substring(0, 32)! }, {} as ZIMRoomAdvancedConfig)
        .then(() => {
          console.log("Logged into room " + roomId)

          setInterval(updateLiveUserCount, 3000 + Math.floor(Math.random() * 3000))
        })
        .catch((e) => {
          console.log("failed to log into room", { e })
        })
    } else {
      console.log("enterRoom in 1000ms because user isn't logged in")
      setTimeout(enterRoom, 1000)
    }
  }

  const clearLocalStream = () => {
    if (localStreamRef.current) {
      zegoExpressEngine.destroyStream(localStreamRef.current)
    }

    localStreamRef.current = null
    setHasCommentatorVideoOn(true)
    setIsPlayingStream(false)
    setIsTogglingCommentatorVideo(false)
  }

  const startCommentateStreamOrShowTutorial = () => {
    const hasSeenTutorial = localStorage.getItem(HAS_SEEN_COMMENTATOR_TUTORIAL_POPUP_KEY)
    if (!hasSeenTutorial || hasSeenTutorial !== 'true') {
      setIsShowingCommentatorTutorialPopup(true)
      localStorage.setItem(HAS_SEEN_COMMENTATOR_TUTORIAL_POPUP_KEY, 'true')
    } else {
      startCommentateStream()
    }
  }

  const dismissTutorialAndStartCommentatorStream = () => {
    setIsShowingCommentatorTutorialPopup(false)
    startCommentateStream()
  }

  const startCommentateStream = async () => {
    trackLog('startCommentateStream called')
    try {
      setPublishingState(PublishingState.REQUESTING_PUBLISH)
      localStreamRef.current = await zegoExpressEngine.createZegoStream({
        videoBitrate: 300,
        audioBitrate: 48,
        camera: {
          audio: true,
          video: {
            quality: 1,
            width: 320,
            height: 240,
            frameRate: 15
          }
        }
      });
      zegoExpressEngine.setCaptureVolume(localStreamRef.current, 100)
      zegoExpressEngine.startPublishingStream(id!.substring(0, 32), localStreamRef.current, { videoCodec: 'H264' });
      localStreamRef.current.playVideo(localStreamPlayerRef.current!, {
        mirror: false,
        objectFit: "cover",
      })

      return true
    } catch (err) {
      trackLog(err)
      setPublishingState(PublishingState.NOT_PUBLISHING)
      console.error(err)
      return false
    }
  }

  async function stopPublishingStream() {
    trackLog('stopPublishingStream called', publishingState, PublishingState.PUBLISHING)
    if (publishingState === PublishingState.PUBLISHING) {
      trackLog('stopping stream')
      zegoExpressEngine.stopPublishingStream(id!.substring(0, 32))
      clearLocalStream()
    }
  }

  const clearRemoteStream = () => {
    mediaStreamRef.current = null
    setHasCommentatorVideoOn(true)
    setIsPlayingStream(false)
    setIsTogglingCommentatorVideo(false)
  }

  async function startPlayingStream() {
    trackLog('startPlayingStream called')
    try {
      setIsPlayingStream(true)
      mediaStreamRef.current = await zegoExpressEngine.startPlayingStream(id!.substring(0, 32), {});
      const remoteView = zegoExpressEngine.createRemoteStreamView(mediaStreamRef.current);
      remoteView.play(remoteStreamPlayerRef.current!, {
        objectFit: "cover",
        enableAutoplayDialog: false
      })
      return true;
    } catch (err) {
      return false;
    }
  }

  //  Stop Play Stream
  async function stopPlayingStream() {
    trackLog('stopPlayingStream called', 'id:', id!.substring(0, 32))
    zegoExpressEngine.stopPlayingStream(id!.substring(0, 32));
    clearRemoteStream();
  }

  const toggleCommentatorVideo = async (on: boolean) => {
    if (isTogglingCommentatorVideo) {
      return
    }
    setIsTogglingCommentatorVideo(true)
    await zegoExpressEngine.enableVideoCaptureDevice(localStreamRef.current!, on)
    setHasCommentatorVideoOn(on)
    setIsTogglingCommentatorVideo(false)
  }

  const updateLiveUserCount = () => {
    zim.queryRoomOnlineMemberCount(roomId.substring(0, 32)!)
      .then(function ({ roomID, count }) {
        setRoomLiveUserCount(count)
      })
      .catch(function (err) {
        console.error("failed to fetch live audience count: " + err.message)
      });
  }

  useEffect(() => {
    if (!isCreatingBet) {
      setInCreateBetDisplayName("")
      setInCreateBetOptions(["", ""])
    }
  }, [isCreatingBet]);

  useEffect(() => {
    updateAfterBetAmount(selectedBetOptionIndex, selectedBetId)
  }, [placedBetAmount]);

  const updateAfterBetAmount = (optionIndex: number, userSelectedBetId: string) => {
    if (optionIndex < 0) {
      return
    }

    const selectedBet = bets.find((bet) => bet.id === userSelectedBetId)
    if (!selectedBet) {
      return
    }

    if (isNaN(placedBetAmount)) {
      return
    }

    let betTVLSum = 0
    let otherOptionsBetTVLSum = 0
    for (let i = 0; i < selectedBet.optionTVL.length; i++) {
      betTVLSum += selectedBet.optionTVL[i]

      if (i !== optionIndex) {
        otherOptionsBetTVLSum += selectedBet.optionTVL[i]
      }
    }

    let price = (selectedBet.optionTVL[optionIndex] + placedBetAmount) / (betTVLSum + placedBetAmount)
    if (otherOptionsBetTVLSum === 0) {
      price = 1 / selectedBet.optionTVL.length
    }

    const shares = placedBetAmount / price

    setAfterBetWinningCoinsAmount(placedBetAmount + shares / (selectedBet.optionShares[optionIndex] + shares) *
      otherOptionsBetTVLSum * (1 - overallTakeRate + ((streamCommentatorId && streamCommentatorId !== "") ? 0 : commentatorTakeRate)))
  }

  useEffect(() => {
    if (popupContainerRef.current) {
      popupContainerRef.current.scrollTop = popupContainerRef.current.scrollHeight;
    }
  }, [inCreateBetOptions]);

  useEffect(() => {
    scrollChatToBottom()
  }, [chatMessages, isShowingTrendingBet]);

  const scrollChatToBottom = () => {
    if (chatMessages && streamerRoomChatListRef.current) {
      streamerRoomChatListRef.current.scrollTop = streamerRoomChatListRef.current.scrollHeight;
    }
  }

  const sendSuperChat = () => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    // TODO: add tutorial popup
    if (!chatBoxContent || chatBoxContent.trim() === '') {
      toast.error("Can't send empty super chat!")
      return
    }

    const hasSeenTutorial = localStorage.getItem(HAS_SEEN_SUPERCHAT_TUTORIAL_POPUP_KEY)
    if (!hasSeenTutorial || hasSeenTutorial !== 'true') {
      setIsShowingSuperChatTutorialPopup(true)
      localStorage.setItem(HAS_SEEN_SUPERCHAT_TUTORIAL_POPUP_KEY, 'true')
      return
    }

    setIsShowingSuperChatTutorialPopup(false)

    HttpClient.post<Response<PlaceBetResponse>>('room/sendSuperChat', {
      roomId: id,
      message: chatBoxContent.trim()
    })
      .then((response) => {
        setUserCoins(response.data.userBalance)
        setUserPoints(response.data.userPoints)
        setChatBoxContent("")
        mixpanel.track('USER_SUPERCHAT')
      })
      .catch((e): any => {
        console.log("!!!!failed to create bet", { e });
        toast.error(e.response?.data?.errorMessage ?? "Failed to place bet. Try again.");
      })
  }

  const sendChatMessage = () => {
    const isUserLoggedin = isUserLoggedIn()
    let userOrGuestDisplayName = displayName
    let userOrGuestPfpUrl = profilePicUrl
    if (!isUserLoggedin) {
      userOrGuestDisplayName = localStorage.getItem(GUEST_DISPLAY_NAME_KEY)
      userOrGuestPfpUrl = "https://songmate-public.s3.amazonaws.com/pfp/default_pfp.png"
    }

    if (!chatBoxContent || chatBoxContent.trim() === '') {
      toast.error("Can't send empty chat!")
      return
    }

    let toConversationID = roomId.substring(0, 32)!; // Peer user's ID. 
    let conversationType = 1; // Conversation type, 1-on-1 chat: 0. In-room chat: 1. Group chat: 2. 
    let config = {
      priority: 3, // Set message priority. Low: 1 (by default). Medium: 2. High: 3. 
    };

    let messageTextObj = {
      type: 1, message: JSON.stringify({
        text: chatBoxContent,
        messageType: MessageBodyType.CHAT_MESSAGE,
        userDisplayName: userOrGuestDisplayName,
        userPfpUrl: userOrGuestPfpUrl
      } as MessageBody)
    };

    var notification = {
      onMessageAttached: function (message: any) {
        console.log("message sent notification", { message })
      }
    }

    zim.sendMessage(messageTextObj, toConversationID, conversationType, config, notification)
      .then(function ({ message }) {
        console.log("message sent", { message })
        setChatBoxContent("")
        handleMessage([message])
      })
      .catch(function (err) {
        console.log(err)
        if (err.code === 6000322) {
          zim.enterRoom({ roomID: roomId.substring(0, 32)!, roomName: roomId.substring(0, 32)! }, {} as ZIMRoomAdvancedConfig)
            .then(() => {
              console.log("Logged into room " + id)
              zim.sendMessage(messageTextObj, toConversationID, conversationType, config, notification)
                .then(function ({ message }) {
                  console.log("message sent", { message })
                  setChatBoxContent("")
                  handleMessage([message])
                })
                .catch((e) => {
                  console.log("failed to send message again", { e })
                  toast.error("Failed to send chat. Try again. (Reason: " + e.message + '"');
                })
            })
            .catch((e) => {
              console.log("failed to log into room", { e })
              toast.error("Failed to send chat. Try again. (Reason: " + e.message + '"');
            })
        } else {
          toast.error("Failed to send chat. Try again.")
        }
      });
  }

  const handleRoomStateChange = (roomStateChangedResult: ZIMEventOfRoomStateChangedResult) => {
    if (roomStateChangedResult.state === 0 /** ZIMRoomState.Disconnected */) {
      console.log("handleRoomStateChange", { state: 'disconnected' })
      enterRoom()
    }
  }


  const handleMessage = (messageList: ZIMMessage[]) => {
    trackLog('handleMessage', { messageList })

    for (const message of messageList) {
      if (!message || !message.message) {
        continue
      }

      if (!roomIdRef || !roomIdRef.current || (message.conversationID !== roomIdRef.current.substring(0, 32))) {
        console.log('skip syncing message from other conversations', {
          messageConversationId: message.conversationID,
          roomId: roomId
        })
        continue
      }
      const decodedMessageString = decodeURIComponent(message.message as string)

      console.log("decodedMessageString", { decodedMessageString })

      const messageBody = JSON.parse(decodedMessageString) as MessageBody
      console.log(messageBody)
      handleNewBulletMessage({
        messageId: message.messageID,
        text: messageBody.text ?? "",
        betId: messageBody.betId,
        type: messageBody.messageType,
        userPfpUrl: messageBody.userPfpUrl,
        userDisplayName: messageBody.userDisplayName ?? "",
        color: messageBody.color
      })

      if (messageBody.messageType === MessageBodyType.RESOLVED_BET) {
        console.log(messageBody.betId)
        if (isUserLoggedIn()) {
          updateUserCoinBalance()
        }
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            betId: messageBody.betId,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.PLACED_BET) {
        console.log(messageBody.betId)
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            betId: messageBody.betId,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.ADD_BET) {
        console.log(messageBody.betId)
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            betId: messageBody.betId,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.CHAT_MESSAGE) {
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.userDisplayName + ": " + messageBody.text,
            userPfpUrl: messageBody.userPfpUrl,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.SEND_SUPER_CHAT) {
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.userDisplayName + ": " + messageBody.text,
            userPfpUrl: messageBody.userPfpUrl,
            type: messageBody.messageType,
            color: messageBody.color
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.TRENDING_BET) {
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.UPDATE_STREAM_LINK) {
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.STOP_BET) {
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.SEND_GIFT) {
        if (streamCommentatorId === userAddress) {
          updateUserCoinBalance()
        }

        if (messageBody.type === GIFT_TYPE.ROCKET) {
          animateGiftingRocket()
        }

        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            userPfpUrl: messageBody.userPfpUrl,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.UPDATE_COMMENTATOR) {
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      }
    }
  }

  const animateGiftingRocket = () => {

  }

  const sendGift = (type: string) => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    if (userAddress === streamCommentatorId) {
      toast.error("You can't gift yourself.")
      return
    }

    const hasSeenTutorial = localStorage.getItem(HAS_SEEN_GIFTING_ROCKET_TUTORIAL_POPUP_KEY)
    if (!hasSeenTutorial || hasSeenTutorial !== 'true') {
      setIsShowingGiftingRocketTutorialPopup(true)
      localStorage.setItem(HAS_SEEN_GIFTING_ROCKET_TUTORIAL_POPUP_KEY, 'true')
      return
    }

    setIsShowingGiftingRocketTutorialPopup(false)

    HttpClient.post<Response<PlaceBetResponse>>('room/sendGift', {
      roomId: id,
      type: type
    })
      .then((response) => {
        setUserCoins(response.data.userBalance)
        setUserPoints(response.data.userPoints)
        mixpanel.track('USER_GIFT', { type: type })
      })
      .catch((e): any => {
        console.log("!!!!failed to create bet", { e });
        toast.error(e.response?.data?.errorMessage ?? "Failed to place bet. Try again.");
      })
  }

  const createBet = () => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    setIsCreatingBet(true)
  }

  const onEnterPlaceBetSection = (betId: string, optionIndex: number) => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    selectBetOption(betId, optionIndex)
    updateAfterBetAmount(optionIndex, betId)
  }

  const selectBetOption = (betId: string, optionIndex: number) => {
    setSelectedBetId(betId)
    setSelectedBetOptionIndex(optionIndex)
    setPlacedBetAmount(10)
  }

  const onSharingBet = (betId: string) => {
    setSelectedBetIdForSharing(betId)
    setIsShowingSharePopup(true)
  }

  const onCloseSharingBet = () => {
    setSelectedBetIdForSharing("")
    setIsShowingSharePopup(false)
  }

  const placeBetOnBackend = async () => {
    if (placedBetAmount < 2) {
      toast.error("Please at least bet 2 USD")
      return
    }

    setIsPlacingBetOnBackend(true)
    const selectedBetIdCopy = selectedBetId.valueOf()
    HttpClient.post<Response<PlaceBetResponse>>('bet/make', {
      betId: selectedBetIdCopy,
      roomId: id,
      optionIndex: selectedBetOptionIndex,
      amount: placedBetAmount
    })
      .then((response) => {
        setUserCoins(response.data.userBalance)
        setUserPoints(response.data.userPoints)
        setBets((prevBets) => {
          const updatedBets: Bet[] = [...prevBets]
          for (let i = 0; i < updatedBets.length; i++) {
            if (updatedBets[i].id === selectedBetIdCopy) {
              updatedBets[i] = getBetFromBetResponse(response.data.bet)
            }
          }

          return updatedBets
        })
        if (trendingBet?.id === response.data.bet.betId) {
          setTrendingBet(getBetFromBetResponse(response.data.bet))
        }
        selectBetOption("", -1)
        setIsPlacingBetOnBackend(false)

        mixpanel.track('USER_BETTED', { amount: placedBetAmount })
      })
      .catch((e): any => {
        console.log("!!!!failed to create bet", { e });
        toast.error(e.response?.data?.errorMessage ?? "Failed to place bet. Try again.");
        setIsPlacingBetOnBackend(false)
      })
  }

  const onInCreateBetOptionChanged = (newOptionName: string, index: number) => {
    setInCreateBetOptions((previousOptions) => {
      const newOptions = [...previousOptions];
      newOptions[index] = newOptionName
      return newOptions;
    })
  }

  const onCoinRemovedInCreationFlow = (index: number) => {
    setInCreateBetOptions((previousOptions) => {
      const newOptions = [...previousOptions];
      newOptions.splice(index, 1);
      return newOptions;
    })
  }

  const createBetOnBackend = async () => {
    if (!inCreateBetDisplayName || inCreateBetDisplayName.length === 0) {
      toast.error("Bet description can't be empty")
      return
    }

    let filteredBetOptions = []
    for (const betOption of inCreateBetOptions) {
      if (!betOption || betOption.trim().length === 0) {
        continue
      }

      filteredBetOptions.push(betOption.trim())
    }

    if (filteredBetOptions.length < 2) {
      toast.error("Need at least 2 non-empty outcome options")
      return
    }

    if (userCoins < betCreationCost) {
      toast.error("Need " + betCreationCost + " USD to create bet. Top up your account.")
      return
    }

    setIsCreatingBetOnBackend(true)
    HttpClient.post<Response<CreateBetOrStreamResponse>>('bet/create', {
      roomId: id,
      betContent: inCreateBetDisplayName,
      optionDisplayNames: filteredBetOptions
    })
      .then((response) => {
        const data = response.data

        fetchRoomInfo()
        setIsCreatingBet(false)
        setIsCreatingBetOnBackend(false)
        setUserCoins(data.userCoins)
        setUserPoints(data.userPoints)
      })
      .catch((e) => {
        console.log("!!!!failed to create bet", { e });
        toast.error(e.message);
        setIsCreatingBetOnBackend(false)
      })
  }

  const markBetAsTrendingOnBackend = () => {
    HttpClient.post<Response<RoomResponse>>('room/trending', {
      roomId: roomId,
      trendingBetId: selectedBetIdForMod
    })
      .then((response) => {
        const data = response.data

        fetchRoomInfo()
        hideMarkTrendingBetPopup()
        setIsShowingTrendingBet(true)
        toast.success("Trending bet updated");
      })
      .catch((e) => {
        console.log("!!!!failed to mark bet as trending", { e });
        toast.error(e.message);
      })
  }

  const stopBetOnBackend = () => {
    const betId = isShowingTrendingBet ? trendingBet!.id : selectedBetIdForMod
    HttpClient.post<Response<RoomResponse>>('bet/stop', {
      betId: betId
    })
      .then((response) => {
        fetchRoomInfo()
        toast.success("Bet stopped");
        hideShowingStopBetPopup()
      })
      .catch((e) => {
        console.log("!!!!failed to resolve bet", { e });
        toast.error(e.message);
      })
  }

  const resolveBetAsTrendingOnBackend = () => {
    const betId = isShowingTrendingBet ? trendingBet!.id : selectedBetIdForMod
    HttpClient.post<Response<RoomResponse>>('bet/resolve', {
      betId: betId,
      winningOptionIndex: selectedResolveOptionIndex
    })
      .then((response) => {
        fetchRoomInfo()
        toast.success("Bet resolved");
        hideResolveBetPopup()
      })
      .catch((e) => {
        console.log("!!!!failed to resolve bet", { e });
        toast.error(e.message);
      })
  }

  const markStreamLiveStatusOnBackend = () => {
    const betId = isShowingTrendingBet ? trendingBet!.id : selectedBetIdForMod
    HttpClient.post<Response<UpdateRoomLivenessResponse>>('room/markStreamOver', {
      roomId: roomId,
      isStreamOver: !isStreamOver
    })
      .then((response) => {
        fetchRoomInfo()
        toast.success("Stream marked as " + (response.data.room.isStreamOver ? "offline" : "live"));
        setIsShowingMarkStreamLiveStatusPopup(false)
      })
      .catch((e) => {
        console.log("!!!!failed to resolve bet", { e });
        toast.error(e.message);
      })
  }

  const showUpdateCommentatorPopup = () => {
    setInUpdateCommentatorAddress(streamCommentatorId ?? "")
    setIsShowingUpdateCommentatorPopup(true)
  }

  const updateCommentatorOnBackend = () => {
    HttpClient.post<Response<UpdateRoomCommentatorIdResponse>>('/room/update_commentator_id', {
      roomId: roomId,
      commentatorId: inUpdateCommentatorAddress
    })
      .then((response) => {
        toast.success("Stream commentator is set!");
        setStreamCommentatorId(response.data.commentatorId)
        setStreamCommentatorDisplayName(response.data.commentatorDisplayName)
        setStreamCommentatorProfilePicUrl(response.data.commentatorProfilePicUrl)
        setIsShowingUpdateCommentatorPopup(false)
      })
      .catch((e) => {
        console.log("!!!!failed to update stream commentator", { e });
        toast.error(e.response.data.errorMessage);
      })
  }

  const calcOddsFromTVL = (betTVL: number, optionTVLs: number[]): string[] => {
    if (betTVL === 0) {
      const odds = Math.round(100 / optionTVLs.length) + "%"
      return optionTVLs.map((_tvl) => odds)
    }

    const odds: string[] = []
    for (const optionTVL of optionTVLs) {
      odds.push(Math.round(100 * optionTVL / betTVL) + "%")
    }
    return odds
  }

  const getBetFromBetResponse = (bet: BetResponse) => {
    const betTVL = bet.optionTVL.reduce((sum, current) => sum + current, 0)
    return {
      id: bet.betId,
      title: bet.betContent,
      tvl: betTVL.toFixed(0),
      tvlAmount: betTVL,
      options: bet.optionDisplayNames,
      resolvedOption: bet.resolvedOption,
      stoppedTakingBets: bet.stoppedTakingBets,
      createdAt: bet.createdAt,
      userBettedOptions: bet.userBettedOptions,
      odds: calcOddsFromTVL(betTVL, bet.optionTVL),
      optionTVL: bet.optionTVL,
      optionShares: bet.optionShares,
      takeRate: bet.takeRate
    } as Bet
  }

  const fetchRoomInfo = async () => {
    HttpClient.post<Response<RoomResponse>>('room', { roomId: id })
      .then((response) => {
        const data = response.data
        console.log("fetchRoomInfo", { data })
        trackLog('setting roomId', data.roomId)
        setRoomId(() => data.roomId)
        setStreamId((data.newStreamId && data.newStreamId !== "") ? data.newStreamId : data.roomId)
        setStreamTitle(data.streamTitle)
        setStreamCreatorId(data.creatorId)
        setStreamCommentatorId(() => data.commentatorId)
        setStreamCommentatorDisplayName(data.commentatorDisplayName)
        setStreamCommentatorProfilePicUrl(data.commentatorProfilePicUrl)
        setInUpdateCommentatorAddress(data.commentatorId ?? "")
        setBets(data.bets.map((bet) => getBetFromBetResponse(bet)))
        setTrendingBet(data.trendingBet ? getBetFromBetResponse(data.trendingBet) : undefined)
        setIsStreamOver(data.isStreamOver ?? false)
        setStreamStartTime(data.streamStartTime)
      })
      .catch((e) => {
        console.log("getBetFromBetResponse failed", e)
        toast.error("Failed to refresh. Try again (Reason: " + e.message + '"');
      })
  }

  const handleChatInputKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      sendChatMessage()
    }
  }

  const cancelPlacingBet = () => {
    setSelectedBetId("")
    setSelectedBetOptionIndex(-1)
  }

  const showResolveBetPopup = (betId: string) => {
    setIsResolvingBet(true)
    setSelectedBetIdForMod(betId)
  }

  const hideResolveBetPopup = () => {
    setIsResolvingBet(false)
    setSelectedResolveOptionIndex(0)
    setSelectedBetIdForMod("")
  }

  const showMarkTrendingBetPopup = (betId: string) => {
    setIsMarkingTrendingBet(true)
    setSelectedBetIdForMod(betId)
  }

  const hideMarkTrendingBetPopup = () => {
    setIsMarkingTrendingBet(false)
    setSelectedBetIdForMod("")
  }

  const showingStopBetPopup = (betId: string) => {
    setIsShowingStopBetPopup(true)
    setSelectedBetIdForMod(betId)
  }

  const hideShowingStopBetPopup = () => {
    setIsShowingStopBetPopup(false)
    setSelectedBetIdForMod("")
  }

  const copyShareLinkAddress = async (link: string) => {
    await navigator.clipboard.writeText(link)
    toast.success("Link copied!")
  }

  const abortingCreatingStream = () => {
    setIsShowingSwapStreamLinkPopup(false)
    setInCreateSwapStreamUrl("")
  }

  const abortingReportStream = () => {
    setIsShowingReportStreamPopup(false)
    setInCreateReportStreamReason("")
  }

  const reportStreamLinkOnBackend = async () => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    if (!inCreateReportStreamReason || inCreateReportStreamReason === "") {
      toast.error("Report reason can't be empty")
      return
    }

    await HttpClient.post<Response<UpdateStreamResponse>>('room/report', {
      roomId: roomId,
      reason: inCreateReportStreamReason
    })
      .then((response) => {
        const data = response.data

        abortingReportStream()
        toast.success("Report sucessful! Thank you!")
      })
      .catch((e) => {
        console.log("!!!!report stream link", { e });
        toast.error("Failed to report stream. Try again. (Reason: " + e.message + '"');
      })
  }

  const swapStreamLinkOnBackend = async () => {
    if (!inCreateSwapStreamUrl || inCreateSwapStreamUrl.length === 0) {
      toast.error("Stream link can't be empty")
      return
    }

    const streamLink = getStreamLink(inCreateSwapStreamUrl, toast)
    console.error(streamLink)
    if (!streamLink.inCreateChannelName || !streamLink.inCreateStreamerUserName) {
      return
    }

    await HttpClient.post<Response<UpdateStreamResponse>>('room/update_stream_id', {
      streamerUserName: streamLink.inCreateStreamerUserName,
      channelName: streamLink.inCreateChannelName,
      roomId: roomId
    })
      .then((response) => {
        const data = response.data

        abortingCreatingStream()
        if (data.newStreamId) {
          setStreamId(data.newStreamId)
          toast.success("Swapped stream link!")
        } else {
          toast.error("Failed to swap link. Try again.")
        }
      })
      .catch((e) => {
        console.log("!!!!swap stream link", { e });
        toast.error("Failed to swap link. Try again. (Reason: " + e.message + '"');
      })
  }

  const isModOrStreamCreator = () => {
    return isCommunityMod || isResolver || (streamCreatorId === userAddress)
  }

  const getBetsUI = (betsToDisplay: Bet[], isTrendingBet: boolean) => {
    return betsToDisplay.map((bet, betIndex) => (
      <div id={"betid~" + bet.id} key={"betid~" + bet.id}>
        {
          (selectedBetId === bet.id) ? (
            <div className={betIndex === betsToDisplay.length - 1 ? ("betItemContainer lastBetItemContainer " + (isModOrStreamCreator() ? "lastBetItemContainerForMod" : ""))
              : "betItemContainer"}>
              <div className="selectedBetItemHeader">
                <div>{bet.options[selectedBetOptionIndex]}</div>
                <img onClick={cancelPlacingBet} className="placeBetCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="selectedBetInputContainer">
                <input type="number" className="selectedBetAmountInput" onKeyDown={(evt) => evt.key === '.' && evt.preventDefault()} value={placedBetAmount} onChange={e => setPlacedBetAmount(parseFloat(e.target.value))} placeholder="10"></input>
                <div className="buttonContainer">
                  <button className="btn selectedBetPlus" onClick={() => setPlacedBetAmount(placedBetAmount + 1)}>+1</button>
                  <button className="btn selectedBetPlus" onClick={() => setPlacedBetAmount(placedBetAmount + 10)}>+10</button>
                </div>
              </div>
              <div className="afterBetWinTicketsContainer">
                <span>estimated to win </span>
                <img className="betBoxToWinCoinImage" src="/coin.png" />
                <span>{afterBetWinningCoinsAmount.toFixed(1)}</span>
              </div>
              <button disabled={isPlacingBetOnBackend} onClick={placeBetOnBackend} className="btn placeBetBtn">
                <div className="placeBetHintContainer">
                  {isPlacingBetOnBackend ? "Placing Bet..." : "Place Bet"}
                </div>
              </button>
            </div>
          ) : (
            <div className={betIndex === betsToDisplay.length - 1 ? ("betItemContainer lastBetItemContainer " + (isModOrStreamCreator() ? "lastBetItemContainerForMod" : ""))
              : "betItemContainer"}>
              <div className="betTitleContainer">{bet.title}</div>
              {
                bet.options.map((option, optionIndex) => (
                  <>
                    <div className="betItemOptionsContainer">
                      <div className="betItemOptionName">{option}</div>
                      <div className="betItemOptionOdds">
                        {
                          (bet.resolvedOption >= 0) ? (
                            (bet.resolvedOption === optionIndex) ? '✅' : '❌'
                          ) : (
                            <>
                              <div className="betItemOptionOddsValue">{bet.odds[optionIndex]}</div>
                              <div className="betItemOptionOddsHint">Odds</div>
                            </>
                          )
                        }
                      </div>
                      {
                        bet.resolvedOption < 0 &&
                        (<button className="btn betBtn" onClick={() => onEnterPlaceBetSection(bet.id, optionIndex)}>Bet</button>)
                      }
                    </div>
                    {
                      bet.userBettedOptions[optionIndex] > 0 && (
                        <div className="userBettedAmount">(You betted {bet.userBettedOptions[optionIndex]} USD)</div>
                      )
                    }
                  </>
                ))
              }
              <div className="betItemFooter">
                <div className="betItemTVL">Total Wagered: <img className="betBoxCoinImage" src="/coin.png" />{bet.tvl}</div>
                {
                  (bet.resolvedOption < 0 && !bet.stoppedTakingBets) ? (
                    <div className="betLivenessContainer">
                      <div className="betLivenessIcon"></div>
                      <div>Live</div>
                      <div className="shareBetContainer" onClick={() => onSharingBet(bet.id)}>
                        <img className="shareBetIcon" src="/share_button.png"></img>
                        <div>Share</div>
                      </div>
                    </div>
                  ) : (
                    <div className="betLivenessContainer">
                      <div>{bet.resolvedOption >= 0 ? "Resolved" : "Pending Resolve"}</div>
                    </div>
                  )
                }
              </div>
              <div className="betItemFooter">

                {
                  (isShowingTrendingBet && roomLiveUserCount && roomLiveUserCount > 0) ? (
                    <div>Live Users: {roomLiveUserCount}</div>
                  ) : (
                    <></>
                  )
                }
                <div className={(isShowingTrendingBet && roomLiveUserCount && roomLiveUserCount > 0) ?
                  "betModFooterRightItems" : "betModFooterLeftItems"
                }>
                  {
                    isResolver && bet.resolvedOption < 0 && (
                      <div className="betModFooterItem" onClick={() => showResolveBetPopup(bet.id)}>
                        <div className="betModFooterItemImage">✅</div>
                        <div>Resolve</div>
                      </div>
                    )
                  }
                  {
                    isResolver && !isShowingTrendingBet && (
                      <div className="betModFooterItem" onClick={() => showMarkTrendingBetPopup(bet.id)}>
                        <img className="betModFooterItemImage" src="/fire.png"></img>
                        <div>Trending</div>
                      </div>
                    )
                  }
                </div>
              </div>
              {
                !isResolver && !isCommunityMod && isTrendingBet && (
                  <div className="betModFooter">
                    <div className="betModFooterItem" onClick={() => setIsShowingReportStreamPopup(true)}>
                      <div className="betModFooterItemImage">🚨</div>
                      <div className="betModFooterItemText">Report</div>
                    </div>
                  </div>
                )
              }
              {
                isCommunityMod && (
                  <div className="betModFooter">
                    {
                      bet.resolvedOption < 0 && (
                        <>
                          {
                            !isShowingTrendingBet && (
                              <div className="betModFooterItem" onClick={() => showMarkTrendingBetPopup(bet.id)}>
                                <img className="betModFooterItemImage" src="/fire.png"></img>
                                <div>Trending</div>
                              </div>
                            )
                          }
                        </>
                      )
                    }
                    {
                      !bet.stoppedTakingBets && (
                        <div className="betModFooterItem" onClick={() => showingStopBetPopup(bet.id)}>
                          <div className="betModFooterItemImage">🛑</div>
                          <div>Stop</div>
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    ))
  }

  return (
    <div className="streamContainer">
      {
        isCreatingBet && (
          <>
            <div className="overlay" onClick={() => setIsCreatingBet(false)}></div>
            <div className="createBetContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={() => setIsCreatingBet(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="betDisplayNameContainer">
                <div>What is the bet?</div>
                <input value={inCreateBetDisplayName} onChange={e => setInCreateBetDisplayName(e.target.value)} className="betDisplayNameInput" placeholder="Can Messi score a goal?"></input>
              </div>
              <div className="betOptionsContainer">
                <div>Outcome Options (need at least 2)</div>
                {
                  inCreateBetOptions.map((option, index) => (
                    <div className="betOptionItemContainer">
                      <input value={option} onChange={e => onInCreateBetOptionChanged(e.target.value, index)} className="optionNameInput" placeholder={index === 0 ? "Yes" : "No"}></input>
                      <img onClick={() => onCoinRemovedInCreationFlow(index)} className="removeOptionIcon" src="/removeIcon.png"></img>
                    </div>
                  ))
                }
                <div onClick={() => onInCreateBetOptionChanged("", inCreateBetOptions.length)} className="addMoreOptions">Add a new option</div>
              </div>
              <div className="createBetFooter">
                <button className="btn create-bet-btn" disabled={isCreatingBetOnBackend} onClick={createBetOnBackend}>{isCreatingBetOnBackend ? 'Creating...' : 'Create Bet'}</button>
                <div className="createBetFeeHint">* Costs {betCreationCost} coin. Earn {(betCreationTakeRate * 100).toFixed(1)}% fees of this bet (unless bet gets refunded)</div>
                <div className="lastCreateBetFeeHint">* Make highly specific questions to increase the likelihood of trending bets.</div>
              </div>
            </div>
          </>
        )
      }
      {
        isShowingCommentatorTutorialPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingCommentatorTutorialPopup(false)}></div>
            <div className="commentatorTutorialPopupContainer popup">
              <div className="popupHeader">
                <img onClick={() => setIsShowingCommentatorTutorialPopup(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="tutorialTextImportant">
                Please use a headphone.
              </div>
              <div className="commentatorTutorialText">
                Otherwise your audience will hear overlapped sound of your livestream and theirs.
              </div>
              <div className="tutorialCallToActionFooter" onClick={dismissTutorialAndStartCommentatorStream}>[I will wear a headphone. Start streaming.]</div>
            </div>
          </>
        )
      }
      {
        isShowingGiftingRocketTutorialPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingGiftingRocketTutorialPopup(false)}></div>
            <div className="popup">
              <div className="popupHeader">
                <img onClick={() => setIsShowingGiftingRocketTutorialPopup(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="tutorialTextImportant">
                You will tip ${giftPrice[GIFT_TYPE.ROCKET]} worth of rocket to {streamCommentatorDisplayName}.
              </div>
              <div className="tutorialCallToActionFooter" onClick={() => sendGift(GIFT_TYPE.ROCKET)}>[ Tip now ]</div>
            </div>
          </>
        )
      }
      {
        isShowingSuperChatTutorialPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingSuperChatTutorialPopup(false)}></div>
            <div className="popup">
              <div className="popupHeader">
                <img onClick={() => setIsShowingSuperChatTutorialPopup(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="tutorialTextImportant">
                Your chat will be displayed in red color. Your ${giftPrice[GIFT_TYPE.SUPER_CHAT]} tip goes to {streamCommentatorDisplayName}.
              </div>
              <div className="tutorialCallToActionFooter" onClick={sendSuperChat}>[ Send my super chat now ]</div>
            </div>
          </>
        )
      }
      {
        isShowingSharePopup && (
          <>
            <div className="overlay" onClick={onCloseSharingBet}></div>
            <div className="sharePopupContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={onCloseSharingBet} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="sharePopupBody">
                <div className="twitterShare">
                  <img className="twitterShareIcon" src="/twitter_icon.png"></img>
                  <a className="twitterShareLink"
                    href={`https://twitter.com/intent/tweet?text="${bets.find((bet) => bet.id === selectedBetIdForSharing)?.title}"%0ACome watch and bet live with me on @thelivebet:%0A${window.location.href + '?bid=' + selectedBetIdForSharing}`}>
                    Share on X (Twitter)
                  </a>
                </div>
                <div className="shareUrlContainer">
                  <div className="shareUrlText">{window.location.href + '?bid=' + selectedBetIdForSharing}</div>
                  <div className="btn shareUrlContainerCopyButton" onClick={() => copyShareLinkAddress(window.location.href + '?bid=' + selectedBetIdForSharing)}>copy</div>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        isMarkingTrendingBet && (
          <>
            <div className="overlay" onClick={hideMarkTrendingBetPopup}></div>
            <div className="markTrendingContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={hideMarkTrendingBetPopup} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmMarkTrendingText">
                Are you sure to mark this bet as trending?
              </div>
              <button className="btn create-bet-btn" onClick={markBetAsTrendingOnBackend}>Confirm</button>
            </div>
          </>
        )
      }
      {
        isShowingStopBetPopup && (
          <>
            <div className="overlay" onClick={hideShowingStopBetPopup}></div>
            <div className="markTrendingContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={hideShowingStopBetPopup} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmMarkTrendingText">
                Are you sure to {bets.find((bet) => bet.id === selectedBetIdForMod)?.stoppedTakingBets ? "resume" : "stop"} this bet?
              </div>
              <button className="btn create-bet-btn" onClick={stopBetOnBackend}>Confirm</button>
            </div>
          </>
        )
      }
      {
        isResolvingBet && (
          <>
            <div className="overlay" onClick={hideResolveBetPopup}></div>
            <div className="resolveBetContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={hideResolveBetPopup} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmMarkTrendingText">
                {
                  (isShowingTrendingBet ? trendingBet : bets.find((bet) => bet.id === selectedBetIdForMod))?.options.map((option, index) => (
                    <button className={selectedResolveOptionIndex === index ? "selectedResolveOptionButton" : "unselectedResolveOptionButton"}
                      onClick={() => setSelectedResolveOptionIndex(index)}>{option}</button>
                  ))
                }
                <button className={selectedResolveOptionIndex === 1000001 ? "selectedResolveOptionButton" : "unselectedResolveOptionButton"}
                  onClick={() => setSelectedResolveOptionIndex(1000001)}>Undecided</button>
              </div>
              <div className="createBetFooter">
                <button className="btn create-bet-btn" onClick={resolveBetAsTrendingOnBackend}>Confirm</button>
              </div>
            </div>
          </>
        )
      }
      {
        isShowingMarkStreamLiveStatusPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingMarkStreamLiveStatusPopup(false)}></div>
            <div className="markOfflineContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={() => setIsShowingMarkStreamLiveStatusPopup(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmMarkTrendingText">
                Are you sure to mark this bet as {isStreamOver ? "live" : "offline"}?
              </div>
              <div className="createBetFooter">
                <button className="btn create-bet-btn" onClick={markStreamLiveStatusOnBackend}>Confirm</button>
              </div>
            </div>
          </>
        )
      }
      {
        isShowingUpdateCommentatorPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingUpdateCommentatorPopup(false)}></div>
            <div className="updateCommentatorPopupContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={() => setIsShowingUpdateCommentatorPopup(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmUpdateCommentatorText">
                <div>Commentator wallet address</div>
                <div className="confirmUpdateCommentatorInputContainer">
                  <input className="inCreateUpdateCommentatorInput" value={inUpdateCommentatorAddress} onChange={e => setInUpdateCommentatorAddress(e.target.value)} placeholder="Enter address..."></input>
                  <div className="useMyAddressAsCommentatorText" onClick={() => setInUpdateCommentatorAddress(userAddress)}>Use My Address</div>
                </div>
              </div>
              <div className="createBetFooter">
                <button className="btn create-bet-btn" onClick={updateCommentatorOnBackend}>Confirm</button>
              </div>
            </div>
          </>
        )
      }
      {
        isShowingSwapStreamLinkPopup && (
          <>
            <>
              <div className="overlay" onClick={abortingCreatingStream} />
              <div className="swapStreamContainer popup">
                <div className="createStreamHeader">
                  <img onClick={abortingCreatingStream} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
                </div>
                <div className="streamUserNameContainer">
                  <div>Swap Stream Link</div>
                  <div className="streamPlatformHint">(supports Youtube, Twitch, Twitter, Kick and streamed.su streams)</div>
                  <input value={inCreateSwapStreamUrl} onChange={e => setInCreateSwapStreamUrl(e.target.value)} className="inCreateStreamerUserNameInput" placeholder="https://www.youtube.com/watch?v=Z8UTqxU3Cdo"></input>
                </div>
                <div className="createBetFooter">
                  <button className="btn create-bet-btn" onClick={swapStreamLinkOnBackend}>Confirm</button>
                </div>
              </div>
            </>s
          </>
        )
      }
      {
        isShowingReportStreamPopup && (
          <>
            <>
              <div className="overlay" onClick={abortingReportStream} />
              <div className="createStreamContainer">
                <div className="createStreamHeader">
                  <img onClick={abortingReportStream} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
                </div>
                <div className="streamUserNameContainer">
                  <div>Report Stream</div>
                  <input value={inCreateReportStreamReason} onChange={e => setInCreateReportStreamReason(e.target.value)} className="inCreateStreamerUserNameInput" placeholder="Enter reason..."></input>
                </div>
                <div className="createBetFooter">
                  <button className="btn create-bet-btn" onClick={reportStreamLinkOnBackend}>Report Stream</button>
                </div>
              </div>
            </>s
          </>
        )
      }
      {
        getStreamType(streamId) === SelectedChannel.FEATURED ? (
          <div style={{ position: "relative" }}>
            <BulletCanvas messages={bulletMessages} />
            <div className="streamPlayer featuredStreamPlayer">
              {
                (streamTitle && streamTitle !== "") && (
                  <div className="countdownTextContainer">{streamTitle}</div>
                )
              }
              {
                streamCommentatorId && (
                  <div className="streamCommentatorInfo">
                    {`🎙️Commentator: ${streamCommentatorDisplayName}`}
                  </div>
                )
              }
              <div className="countdownTextContainer">
                {
                  formatTimestamp(countdownRemainingTime)
                }
              </div>
              <div className="featuredStreamButtonContainer">
                {
                  isModOrStreamCreator() && (getStreamType(streamId)) && (
                    <div className="addCommentatorOfStream" onClick={() => setIsShowingSwapStreamLinkPopup(true)}>
                      Attach Stream Link
                    </div>
                  )
                }
                {
                  getAddToCalendarButton(false, streamTitle!, streamStartTime!, window.location.href)
                }
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div style={{ position: "relative" }}>
              <BulletCanvas messages={bulletMessages} />
              {getStreamFrame(streamId, false /*isPreview*/, screenWidth, streamStartTime)}

              {(streamCommentatorId !== userAddress) && isPlayingStream && !props.hasInteracted && (
                <UnmuteButton />
              )}

              {
                (streamCommentatorId === userAddress) && (
                  <div className="commentateStreamPlayer" ref={localStreamPlayerRef}>
                    {
                      !hasCommentatorVideoOn && (
                        <>
                          <img className="commentatorPfpInStreamPlayer" src={streamCommentatorProfilePicUrl}></img>
                          <div className="commentatorPfpInStreamMicIcon" >🎙️</div>
                        </>
                      )
                    }
                  </div>
                )
              }
              {
                (streamCommentatorId !== userAddress) && isPlayingStream && (
                  <div id="viewerStreamPlayer" className="commentateStreamPlayer" ref={remoteStreamPlayerRef}>
                    {
                      !hasCommentatorVideoOn && (
                        <>
                          <img className="commentatorPfpInStreamPlayer" src={streamCommentatorProfilePicUrl}></img>
                          <div className="commentatorPfpInStreamMicIcon" >🎙️</div>
                        </>
                      )
                    }
                  </div>
                )
              }
            </div>
            <div>
              {
                (userAddress && streamCommentatorId === userAddress) && (
                  (publishingState === PublishingState.PUBLISHING) ? (
                    <div className="commentatorToolsContainer">
                      <div className="addCommentatorOfStream" onClick={stopPublishingStream}>
                        <img className="commentatorVideoIcon" src="/stop-sign.png"></img>
                        <div className="commentatorVideoText">Stop Commentate</div>
                      </div>
                      {
                        hasCommentatorVideoOn ? (
                          <div className="addCommentatorOfStream" onClick={() => toggleCommentatorVideo(false)}>
                            <img className="commentatorVideoIcon" src="/no_video.png"></img>
                            <div className="commentatorVideoText">Turn Off Camera</div>
                          </div>
                        ) : (
                          <div className="addCommentatorOfStream" onClick={() => toggleCommentatorVideo(true)}>
                            <img className="commentatorVideoIcon" src="/has_video.png"></img>
                            <div className="commentatorVideoText">Turn On Camera</div>
                          </div>
                        )
                      }
                    </div>
                  ) : (
                    (publishingState === PublishingState.REQUESTING_PUBLISH) ? (
                      <div className="addCommentatorOfStream">
                        <div className="commentatorVideoText">🕒 Starting...</div>
                      </div>
                    ) : (
                      <div className="addCommentatorOfStream" onClick={startCommentateStreamOrShowTutorial}>
                        <div className="commentatorVideoText">🎙️ Start Commentate</div>
                      </div>
                    )
                  )
                )
              }
            </div>
          </div>
        )
      }
      <div className="streamChat">
        <div className="streamTabContainer">
          <div className={isShowingTrendingBet ? "selectedStreamTabContainer" : "streamTabContainerItem"}
            onClick={() => setIsShowingTrendingBet(true)}>
            Trending Bet
          </div>
          <div className={!isShowingTrendingBet ? "selectedStreamTabContainer rightMostStreamTabContainerItem" :
            "streamTabContainerItem rightMostStreamTabContainerItem"}
            onClick={() => setIsShowingTrendingBet(false)}>
            All Bets ({bets.length})
          </div>
        </div>
        {
          isShowingTrendingBet ? (
            <div className="trendingBetContainer">
              <div className="trendingBetsContainer">
                {
                  bets.length === 0 ? (
                    <div className="noBetsAndCreateContainer">
                      <div className="noBetsContainer">No bets yet.</div>
                      <button className="btn" onClick={createBet}>Create Bet</button>
                    </div>
                  ) : (
                    <div>
                      {
                        getBetsUI(trendingBet ? [trendingBet] : [], true /*isTrendingBet*/)
                      }
                    </div>
                  )
                }
              </div>
              {
                isModOrStreamCreator() && (
                  <div className="betModFooter streamModFooter">
                    <div className="betModFooterItem" onClick={() => setIsShowingMarkStreamLiveStatusPopup(true)}>
                      <div className="betModFooterItemImage">📺</div>
                      <div>{isStreamOver ? "Mark Live" : "Mark Offline"}</div>
                    </div>
                    <div className="betModFooterItem" onClick={() => setIsShowingSwapStreamLinkPopup(true)}>
                      <div className="betModFooterItemImage">🔗</div>
                      <div>Swap</div>
                    </div>
                    <div className="betModFooterItem" onClick={showUpdateCommentatorPopup}>
                      <div className="betModFooterItemImage">🎙️</div>
                      <div>Commentator</div>
                    </div>
                  </div>
                )
              }
              <div className="streamerRoomChatList" ref={streamerRoomChatListRef}>
                {
                  chatMessages.length === 0 ? (
                    <div className="noChatMessageHint">
                      No chat messages yet.
                    </div>
                  ) : (
                    <div>
                      {
                        chatMessages.map((message) =>
                          <div className="chatMessageItemContainer">
                            {
                              getMessageIcon(message)
                            }
                            {
                              message.type === MessageBodyType.ADD_BET ? (
                                <div className="chatMessageDisplayText">
                                  {message.text}
                                </div>
                              ) : (
                                <div style={{color: (message.color ? message.color: "#ffffff")}} className="chatMessageDisplayText">{message.text}</div>
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
              <div className="streamerRoomUserToolBox">
                <div className="streamerRoomChatBox">
                  <textarea value={chatBoxContent} onKeyDown={handleChatInputKeyDown} onChange={e => setChatBoxContent(e.target.value)}
                    placeholder="Enter chat message..." className="streamerRoomChatInput"></textarea>
                  <img onClick={sendChatMessage} className="chatSendButton" src="/sendIcon-white.svg"></img>
                </div>
                {
                  (streamCommentatorId && streamCommentatorId !== "") && (
                    <img onClick={sendSuperChat} className="giftSendButton" src="/sendIcon-gold.svg"></img>
                  )
                }
                {
                  (streamCommentatorId && streamCommentatorId !== "") && (
                    <img onClick={() => sendGift(GIFT_TYPE.ROCKET)} className="giftSendButton giftSendButtonRightMost" src="/gift_rocket.svg"></img>
                  )
                }
              </div>
              <div className="createBet">
                <button className="btn" onClick={createBet}>Create Bet</button>
              </div>
            </div>
          ) : (
            bets.length === 0 ? (
              <div className="noBetsAndCreateContainerFullPage">
                <div className="noBetsContainer">No bets yet.</div>
                <button className="btn" onClick={createBet}>Create Bet</button>
              </div>
            ) : (
              <>
                <div className="betsContainer allBetsBetsContainer">
                  {
                    getBetsUI(bets, false /*isTrendingBet*/)
                  }
                </div>
                <div className="createBet">
                  <button className="btn" onClick={createBet}>Create Bet</button>
                </div>
              </>
            )
          )
        }
      </div>
    </div>
  );
}

export default StreamView;
