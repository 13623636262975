export type Bet = {
    id: string,
    title: string,
    options: string[],
    tvl: string,
    tvlAmount: number,
    odds: string[],
    optionTVL: number[],
    optionShares: number[],
    resolvedOption: number,
    stoppedTakingBets?: boolean,
    createdAt: number,
    userBettedOptions: number[],
    takeRate: number
}

export type Stream = {
    id: string,
    isStreamOver: boolean,
    bettedTVL: number,
    createdAt?: number,
    newStreamId?: string,
    streamTitle?: string,
    streamStartTime?: number,
    commentatorId?: string
}

export type UnresolvedBet = {
    url: string,
    createdAt: number,
    title: string
}

export enum GIFT_TYPE {
    ROCKET = "ROCKET",
    SUPER_CHAT = "SUPER_CHAT"
}