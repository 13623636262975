import './bullet.css'
import { ChatMessage } from '../network/types'
import { getMessageIcon } from './streamView'
export type bulletMessage = ChatMessage & {
    createdAt: number
    floor: number
    withPadding: boolean
}
export const maxFloor = 6;
const streamerHeght = 5; // how many floor the streamer cover
export function BulletCanvas(props: { messages: bulletMessage[] }) {
    return <div className="bulletCanvas">
        <div className='bulletMessagesContainer'>
            {props.messages.map(message => {
                let floorClassName = 'floor' + message.floor
                let animation = 'bulletMove 20s linear forwards'
                if (message.withPadding && message.floor < streamerHeght) {
                    animation = 'bulletMoveWithPadding 20s linear forwards'
                }
                return <div className={'bulletMessageContainer ' + floorClassName} key={message.messageId} style={{ animation }}>
                    {getMessageIcon(message)}
                    <div className='bulletMessageText' style={{ color: (message.color ? message.color: "#ffffff") }}>{message.text}</div>
                </div>
            })}
        </div>
    </div>
}