import { useEffect, useState } from 'react';



export function UnmuteButton() {


    return <div style={{ display: "flex", width: "180px", height: "20px", top: "9vw", right: "10px", position: "absolute", backgroundColor: "#028c9c", color: "white", padding: "10px", textAlign: "center", cursor: "pointer", borderRadius: "5px" }}>
        <div onClick={() => {
            console.log('#')
        }}>Unmute Commentator</div>
    </div>
}